import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

function VendasProdutoPDF(pedidos){

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    pedidos = Array.isArray(pedidos) ? pedidos : [];

    const reportTitle = [
        {
            text: 'Relatorio de Produtos Vendidos',
            fontSize: 18,
            alignment:'center',
            bold: true,
            margin: [20, 20, 0, 45] // left, top, right, bottom
        }
    ];

    const totalQuantidade = pedidos.reduce((acc, item) => acc + (parseInt(item.qtd, 10) || 0), 0);
    const rankingProdutoBodyWithTotal = pedidos.length ? 
        [...pedidos.map(item => [item.descricao || 'N/A', item.qtd || 'N/A']), 
        ['Total de Produtos', totalQuantidade.toString(),]] : 
        [['Nenhum produto encontrado', '']];  

    const dados = pedidos.map((pedido) => {
        return [
            {text: pedido.descricao, fontSize: 10, margin: [0, 2, 0, 2]},
            {text: pedido.qtd, fontSize: 10, margin: [0, 2, 0, 2]}
        ]
    });

    const details = [
        {
            table:{
                headerRows: 1,
                body: [
                    [
                        {text: 'Descrição', style: 'tableHeader', fontSize: 15},
                        {text: 'Quantidade', style: 'tableHeader', fontSize: 15}
                    ], ...rankingProdutoBodyWithTotal,
                ], widths: [250, 130]
            }, layout: "lightHorizontalLines"
        }
    ];

    function Rodape(currentPage, pageCount){
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0] // left, top, right, bottom
            }
        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitios).open();


}

export default VendasProdutoPDF;