import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function VendasPorCupom(salesData) {
  // Carregue as fontes do pdfmake
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  // Verifique se salesData é um array válido
  salesData = Array.isArray(salesData) ? salesData : [];

  // Agrupe os dados por número de venda usando reduce
  const groupedSalesData = salesData.reduce((acc, sale) => {
    if (!sale || !sale.DOCUMENTO) return acc; // Ignore dados inválidos
    if (!acc[sale.DOCUMENTO]) {
      acc[sale.DOCUMENTO] = {
        itens: [],
        data: sale.DATA ?? 'Data não disponível',
        numccf: sale.DOCUMENTO ?? 'Documento não disponível',
        total: 0,
        caixa: sale.CAIXA ?? 'Caixa não disponível',
      };
    }
    acc[sale.DOCUMENTO].itens.push(sale);
    acc[sale.DOCUMENTO].total = sale.TOTAL ?? 0;
    return acc;
  }, {});

  // Calcule o total geral usando reduce
  const total = Object.values(groupedSalesData).reduce((sum, venda) => sum + venda.total, 0);

  // Defina as configurações do documento
  const docDefinition = {
    content: [
      { text: 'Razão Social' },
      { text: 'CNPJ' },
      { text: 'Relatório de Venda', margin: [0, 20, 0, 0], style: 'header' },
      {
        table: {
          headerRows: 1,
          widths: [100, 200, 35, 35, 40, 50],
          body: [
            [{ text: 'Código' }, { text: 'Descrição' }, { text: 'Item' }, { text: 'Qtd' }, { alignment: 'right', text: 'Venda' }, { alignment: 'right', text: 'Total' }],
          ],
        },
        layout: 'noBorders',
        margin: [0, 0, 0, 0],
        fontSize: 8,
        bold: true,
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
        alignment: 'center',
      },
      subheader: {
        fontSize: 12,
        bold: false,
        margin: [0, 10, 0, 5],
      },
      info: {
        margin: [0, 0, 0, 5],
      },
    },
  };

  // Adicione os dados de venda ao documento
  Object.keys(groupedSalesData).forEach((venda) => {
    const vendaData = groupedSalesData[venda];
    console.log(vendaData);
    
    const vendaContent = [
      {
        table: {
          headerRows: 1,
          widths: ['*', 'auto', 'auto', 'auto', 100],
          body: [
            [
              { border: [true, true, false, true], text: 'Caixa ' + (vendaData.caixa ?? 'N/A') },
              { border: [false, true, false, true], text: 'Venda ' + (venda ?? 'N/A') },
              { border: [false, true, false, true], text: 'Numccf: ' + (vendaData.numccf ?? 'N/A') },
              { border: [false, true, false, true], text: 'Data: ' + (vendaData.data ?? 'N/A') },
              { border: [false, true, true, true], text: '' },
            ],
          ],
        },
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          headerRows: 1,
          widths: [100, 200, 35, 35, 40, 50],
          body: [
            ...vendaData.itens.map((sale) => [
              sale.COD_PRODUTO ?? 'Código não disponível',
              sale.DESCRICAO ?? 'Descrição não disponível',
              sale.ITEM ?? 'Item não disponível',
              (Number(sale.QTD ?? 0)).toFixed(3),
              { alignment: 'right', text: (Number(sale.VENDA ?? 0)).toFixed(2) },
              { alignment: 'right', text: (Number(sale.VENDA * sale.QTD ?? 0)).toFixed(2) },
            ]),
          ],
        },
        layout: 'noBorders',
        margin: [0, 0, 0, 0],
        fontSize: 8,
      },
    ];

    const sumario = {
      table: {
        headerRows: 1,
        widths: ['*'],
        body: [
          [{ margin: [0, 0, 10, 0], border: [false, true, false, false], text: 'Total R$ ' + Number(vendaData.total).toFixed(2) }],
        ],
      },
      alignment: 'right',
      margin: [0, 0, 0, 0],
      bold: true,
    };

    vendaContent.push(sumario);

    docDefinition.content.push(...vendaContent);
  });

  // Adicione o rodapé com o total geral
  const rodape = [{
    table: {
      headerRows: 1,
      widths: ['*'],
      body: [
        [{ border: [false, true, false, false], text: 'Total R$ ' + Number(total).toFixed(2) }],
      ],
    },
    alignment: 'right',
    margin: [0, 15, 0, 0],
    bold: true,
  }];

  docDefinition.content.push(...rodape);

  // Gere o documento PDF
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  pdfDocGenerator.open();
}

export default VendasPorCupom;
