import React, { useContext } from "react";
import {BrowserRouter as Router,Routes, Route,redirect, Navigate} from 'react-router-dom';
import Login from "./app/Login/login";
import Home from "./app/home/home";
import Produto from "./app/Produto/produto";
import NovoProduto from "./app/Produto/novoProduto";
import Colaborador from "./app/components/colaborador/colaborador";
import NovoColaborador from "./app/components/colaborador/novoColaborador";
import EditarColaborador from "./app/components/colaborador/editaColaborador";
import EditarProduto from "./app/Produto/editarProduto";
import ItensPedido from "./app/pedidos/itensPedido";
import ListaPedidos from "./app/pedidos/listaPedidos"
import PrivateRoute from "./app/components/private-route/privateRoute";
import Banco from "./app/financeiro/Banco/banco";
import NovoBanco from "./app/financeiro/Banco/novoBanco";

import Contas from "./app/financeiro/Contas/contas";
import NovaConta from "./app/financeiro/Contas/novaConta";
import EditaConta from "./app/financeiro/Contas/editaConta";
import ModalTeste from "./app/Produto/modalteste";
import Grupo from "./app/grupo/grupo";
import NovoGrupo from "./app/grupo/novoGrupo";
import Cadastro from "./app/cadastro/cadastro";
import NovoCadastro from "./app/cadastro/novoCadastro";
import Tesouraria from "./app/financeiro/Tesouraria/tesouraria";
import NfeDashboard from "./app/nfe/nfeDashboard";
import Nfe from "./app/nfe/notaFiscal";
import NovaNfe from "./app/nfe/novaNfe";
import Empresa from "./app/empresa/empresas";
import NovaEmpresa from "./app/empresa/novaEmpresa";
import NovoPedido from "./app/pedidos/novoPedido";
import EntradaEstoque from "./app/estoque/EntradaEstoque";
import NovaEntrada from "./app/estoque/NovaEntrada";
import ControleMesa from "./app/controle-mesa/contole-mesa";
import ResumoPedido from "./app/controle-mesa/resumo-pedido";
import Compra from "./app/compra/compra";
import NovaCompra from "./app/compra/novaCompra";
import ProdutoServico from "./app/Produto/produtoServico";
import NovoServico from "./app/Produto/novoServico";
import Dashboard from "./app/dashboard/dashboard";
import NovaFinalizadora from "./app/financeiro/Finalizadora/novoFinalizadora";
import Finalizadora from "./app/financeiro/Finalizadora/finalizadora";
import PedidosDashboard from "./app/pedidos/pedidosDashboard";
import CadastroMotoqueiro from "./app/cadastro/cadastroMotoqueiro";
import NovoCadastroMoto from "./app/cadastro/novoCadastroMotoqueiro";

function App() {
  

  return (
    <Router>
      <Routes>
         
         <Route exact path= '/' element={<Login/>}/>
         <Route exact path= 'app/modal' element={<ModalTeste/>}/>
                
         <Route exact path= 'app/home' element={<PrivateRoute><Home/></PrivateRoute> }/>
         <Route exact path= 'app/cadastro/:tp' element={<PrivateRoute><Cadastro/></PrivateRoute>}/>         
         <Route exact path= 'app/novocadastro/:id/:tpc/:tag' element={<PrivateRoute><NovoCadastro/></PrivateRoute>}/>
         <Route exact path= 'app/cadastroMotoqueiro' element={<PrivateRoute><CadastroMotoqueiro/></PrivateRoute>}/>  
         <Route exact path= 'app/novoCadastroMotoqueiro/:id/:tag' element={<PrivateRoute><NovoCadastroMoto/></PrivateRoute>}/>       
         <Route exact path= 'app/produto' element={<PrivateRoute><Produto/></PrivateRoute>}/>
         <Route exact path= 'app/produtoservico' element={<PrivateRoute><ProdutoServico/></PrivateRoute>}/>
         <Route exact path= 'app/novoProduto/:cod/:tag' element={<PrivateRoute><NovoProduto/></PrivateRoute>}/>
         <Route exact path= 'app/novoServico/:cod/:tag' element={<PrivateRoute><NovoServico/></PrivateRoute>}/>
         <Route exact path= 'app/grupo' element={<PrivateRoute><Grupo/></PrivateRoute>}/>
         <Route exact path= 'app/novoGrupo/:id' element={<PrivateRoute><NovoGrupo/></PrivateRoute>}/>
         <Route exact path= 'app/editaProduto/:cod' element={<PrivateRoute><EditarProduto/></PrivateRoute>}/>
         <Route exact path= 'app/colaborador' element={<PrivateRoute><Colaborador/></PrivateRoute>}/>
         <Route exact path= 'app/novoColaborador' element={<PrivateRoute><NovoColaborador/></PrivateRoute>}/>
         <Route exact path= 'app/editaColaborador/:cod' element={<PrivateRoute><EditarColaborador/></PrivateRoute>}/>
         <Route exact path= 'app/itenspedido/:cod' element={<PrivateRoute><ItensPedido/></PrivateRoute>}/>
         <Route exact path= 'app/listapedidos' element={<PrivateRoute><ListaPedidos/></PrivateRoute>}/>
         <Route exact path= "app/pedidosDashboard" element={<PrivateRoute><PedidosDashboard/></PrivateRoute>} />
         <Route exact path= 'app/nfeDashboard' element={<PrivateRoute><NfeDashboard/></PrivateRoute>}/>
         <Route exact path= 'app/nfe' element={<PrivateRoute><Nfe/></PrivateRoute>}/>
         <Route exact path= 'app/novanfe/:tp/:cod' element={<PrivateRoute><NovaNfe/></PrivateRoute>}/>
         <Route exact path= 'app/finalizadora' element={<PrivateRoute><Finalizadora/></PrivateRoute>}/>
         <Route exact path= 'app/novoFinalizadora/:id/:tag' element={<PrivateRoute><NovaFinalizadora/></PrivateRoute>}/>
         <Route exact path= 'app/banco' element={<PrivateRoute><Banco/></PrivateRoute>}/>         
         <Route exact path= 'app/novoBanco/:id/:tag' element={<PrivateRoute><NovoBanco/></PrivateRoute>}/>
         {/* <Route exact path= 'app/editaBanco/:cod' element={<PrivateRoute><NovoBanco/></PrivateRoute>}/> */}
         <Route exact path= 'app/contas' element={<PrivateRoute><Contas/></PrivateRoute>}/>
         <Route exact path= 'app/novaConta/:tpc/:id' element={<PrivateRoute><NovaConta/></PrivateRoute>}/>
         <Route exact path= 'app/editaConta/:cod' element={<PrivateRoute><EditaConta/></PrivateRoute>}/>
         <Route exact path= 'app/tesouraria' element={<PrivateRoute><Tesouraria/></PrivateRoute>}/>
         <Route exact path= 'app/empresa' element={<PrivateRoute><Empresa/></PrivateRoute>}/>
         <Route exact path= 'app/novaempresa/:id' element={<PrivateRoute><NovaEmpresa/></PrivateRoute>}/>
         <Route exact path= 'app/pedido/novopedido' element={<PrivateRoute><NovoPedido/></PrivateRoute>}/>
         <Route exact path= 'app/estoque/entrada' element={<PrivateRoute><EntradaEstoque/></PrivateRoute>}/>
         <Route exact path= 'app/estoque/compra' element={<PrivateRoute><Compra/></PrivateRoute>}/>
         <Route exact path= 'app/estoque/novacompra' element={<PrivateRoute><NovaCompra/></PrivateRoute>}/>
         <Route exact path= 'app/estoque/novaentrada' element={<PrivateRoute><NovaEntrada/></PrivateRoute>}/>
         <Route exact path= 'app/pedido/controlemesa' element={<PrivateRoute><ControleMesa/></PrivateRoute>}/>
         <Route exact path= 'app/pedido/resumopedido' element={<PrivateRoute><ResumoPedido/></PrivateRoute>}/>
         <Route exact path= 'app/dashboard' element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
                
      </Routes>
   </Router>   
  );
}

export default App;
