import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

function PedidosPdf(pedidos){

    pedidos = Array.isArray(pedidos) ? pedidos : [];
    
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            text: 'Relatorio de Vendas',
            fontSize: 15,
            alignment:'center',
            bold: true,
            margin: [20, 20, 0, 45] // left, top, right, bottom
        }
    ];


    var soma = pedidos.reduce(function(total, valor) {
        return total + (valor.TOTAL ?? 0);
      }, 0);

    
      
      


    const dados = pedidos.map((pedido) => {
        return [
            {text: pedido.COD_ORCAMENTO ?? '', fontSize: 7, margin: [0, 2, 0, 2]},
            {text: pedido.DATA ?? '', fontSize: 7, margin: [0, 2, 0, 2]},
            {text: pedido.CAIXA ?? '', fontSize: 7, margin: [0, 2, 0, 2],alignment:'center'},
            {text: pedido.DOCUMENTO ?? '', fontSize: 7, margin: [0, 2, 0, 2],alignment:'center'},
            {text: pedido.NOME ?? '', fontSize: 7, margin: [0, 2, 0, 2]},
            {text: pedido.FINALIZADORA ?? '', fontSize: 7, margin: [0, 2, 0, 2]},
            {text: 'R$ '+(Number(pedido.VALOR ?? 0) + Number(pedido.DESCONTO ?? 0)).toFixed(2), fontSize: 7, margin: [0, 2, 2, 2],alignment:'right'},
            {text: 'R$ '+(Number(pedido.DESCONTO ?? 0)).toFixed(2), fontSize: 7, margin: [0, 2, 2, 2],alignment:'right'},
            {text: 'R$ '+(Number(pedido.TOTAL - pedido.DESCONTO ?? 0)).toFixed(2), fontSize: 7, margin: [0, 2, 3, 2],alignment:'right'}
          
        ] 
        
    });

    const details = [
        {
            table:{
                headerRows: 1,
                widths: [40, 50, 30, 50,60, 60,40,50,50],
                body: [
                    [
                        {text: 'Código', style: 'tableHeader', fontSize: 10},
                        {text: 'Data', style: 'tableHeader', fontSize: 10},
                        {text: 'caixa', style: 'tableHeader', fontSize: 10},
                        {text: 'Doc ECF', style: 'tableHeader', fontSize: 10},
                        {text: 'Op. Caixa', style: 'tableHeader', fontSize: 10},
                        {text: 'Finalizadora', style: 'tableHeader', fontSize: 10},
                        {text: 'Sub-Total', style: 'tableHeader', fontSize: 10},
                        {text: 'Desconto', style: 'tableHeader', fontSize: 10},
                        {text: 'Total', style: 'tableHeader', fontSize: 10}
                       
                    ],
                    ...dados,
                    
                    
                ]
            },
            layout: 'lightHorizontalLines' // headerLineOnly
        },{ text: 'Total de Vendas: R$' + soma.toFixed(2), style: 'footer', alignment: 'right'}
    ];

    function Rodape(currentPage, pageCount){
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0] // left, top, right, bottom
            }
        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitios).open();
}

export default PedidosPdf;