// src/components/BarChart.js
import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { useEffect } from 'react';
import api from '../../../services/api';
import { vhostV1 } from '../../funcoes';




const ColumnChart = () => {
  // Transformar o array de objetos em um formato que o Google Charts entende

  const [dataAtual, setDataAtual] = useState(new Date());  
const [finalizadoraVenda, setFinalizadoraVendas] = useState([]);

let config = {
    headers: {
       'Authorization': localStorage.getItem("token")
       }
    }

useEffect(function(){
    api.post(vhostV1('webcash/dashboard/vendafinalizadoras'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        data: dataAtual
         
    },config)
    .then(function(response){
        console.log(response.data.dados);
        if(response.data.dados){
          const dados = response.data.dados;
        setFinalizadoraVendas(dados);
        }else{
          setFinalizadoraVendas([
            {finalizadora: 'N/A',
              valor_final: 0
            }
          ])
        }
        
    

           
        
    })
    .catch(function (error){
        console.log(error);
    });
},[]) 

  const data = [
    ['Descrição', 'R$'], // Cabeçalhos das colunas
    ...finalizadoraVenda.map(item => [item.finalizadora ?? 'N/A', item.valor_final ?? 0]) // Dados do gráfico
  ];

  // Opções do gráfico
  const options = {
    title: 'Gráfico de vendas(R$)',
    legend: { position: 'none' },
    chartArea: { width: '80%' } // Ocultar legenda
  };

  return (
    <div>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="190px"
        data={data}
        options={options}
      />
    </div>
  );
};

export default ColumnChart;
