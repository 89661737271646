import './pedidos.css';
import Navbar from "../navbar/navbar";
import { useState } from 'react';
import BuscaVazaia from '../components/buscavazia/buscaVazia';
import api from '../../services/api';
import { vhostV1 } from '../funcoes';
import { useEffect } from 'react';
import { Link,Navigate, useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import SweetAlert from 'react-bootstrap-sweetalert';
import ModalAdicaoItem from './modalAdicaoItem';
import { log } from 'react-modal/lib/helpers/ariaAppHider';
import Finalizadora from '../financeiro/Finalizadora/finalizadora';
import Card from './card';




function NovoPedido(){       
  
  
    const inputs = document.querySelectorAll("input, select, NumericFormat ,button ");
    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          if (e.target.tagName.toLowerCase() === "button") {
            e.target.click(); // Dispara o evento de clique do botão
          } else {
          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
  
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
          }
        }
      }
      });
    });

    const navigate = useNavigate();
   
   
   let config = {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
    }
    
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, '0');
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();
    const dataAtual = dia + '/' + mes + '/' + ano;
    

   const [finalizadora, setFinalizadora] = useState([]);
   const [finalizadorasFiltradas, setFinalizadorasFiltradas] = useState([]);
   const [razao,setRazao] = useState();
   const [buscaPessoa,setBuscaPessoa] = useState();
   const [resultPessoa,setResultPessoa] = useState();
   const [pessoas,setPessoas] = useState();
   const [responsavel,setResponsavel] = useState();
   const [sResult,setSresult] = useState(0);
   const [confirmacao,setConfirmacao] = useState(false);
   const [confirmacaoId,setConfirmacaoID] = useState('');
   const [result, setResult] = useState(0);
   const [total,setTotal] = useState(0);
   const [Itens,setItens] = useState([]);
   const [idVenda,setIdVenda] = useState(0);
   const [totalPedido,setTotalPedido] = useState(0);
   const [sucesso,setSucesso]      = useState('N')
   const [mensagem, setMensagem]   = useState('');
   const [dataEmissao,setDataEmissao] = useState(new Date)
   const [formaPag,setFormaPag] = useState({codigo: 0,descricao: ''});
   const [recebebido,setRecebito] = useState(0);
   const [sucessoBusca,setSucessoBusca] = useState(false);
   const [produtos,setProdutos] = useState([]);
   const [buscaProduto,setBuscaProduto] = useState('');
   const [cliente,setCliente] = useState(0);
   const [gridProduto,setGridProduto] = useState(false);
   const [subTotal, setSubTotal] = useState(0);
   
   const [valorTotalPago, setValorTotalPago] = useState(0);
   const [pagamentos, setPagamentos] = useState([]);
   const [valorASerPago, setValorASerPago] = useState(totalPedido.toString());
   const [pagamentosRealizados, setPagamentosRealizados] = useState([]);
   const [descricaoForma, setDescricaoForma] = useState('');
   const [confirmaPagamento, setConfirmaPagamento] = useState(false);
   const [autorizaFechamento, setAutorizaFechamento] = useState(false);
   const [navegar, setNavegar] = useState(false);
   const [key, setKey] = useState(0);
   const [load, setLoad] = useState(false);
   const [confirmaEmissao, setConfirmaEmissao] = useState(false);
   const [msgErro, setmsgErro] = useState('');
   const [erroEmissao, setErroEmissao] = useState(false);
   const [erroValidacao, setErrovalidacao] = useState(false);
   
   const [fantasia,setFantasia] = useState('');   
   const [cnpj,setCnpj] = useState('');
   const [inscEstadual,setEstadual] = useState('');
   const [inscMunicipal,setMunicipal] = useState('');
   const [endereco,setendereco] = useState('');
   const [bairro,setbairro] = useState('');
   const [cep,setcep] = useState('');
   const [cidade,setcidade] = useState('');
   const [fone,setfone] = useState('');
   const [uf,setuf] = useState('');
   const [desconto, setDesconto] = useState(0);
   const [descontoDefault, setDescontoDefault] = useState('');
   const [valorDescontado, setValorDescontado] = useState('');
   const [tipoDoc, setTipoDoc] = useState(0);
   const [numeroPedido, setNumeroPedido] = useState(0);
   const [pedidos, setPedidos] = useState([]);
   const [tipo,setTipo] = useState('TODOS');
   const [caixa,setCaixa] = useState('TODOS');  
   const [dataInical,setDataInicial] = useState(new Date()); 
   const [dataFinal,setDataFinal] = useState(new Date());
   const [totalComDesconto, setTotalComDesconto] = useState(0);
   


   function confirmadeleteUser(id){
    setConfirmacaoID(id);
    setConfirmacao(true);
    
   }

   function deleteUser(id){
    api.post(vhostV1('webcash/vendas/cancelaItem'), {
      loja: localStorage.getItem('loja'),
      pedido:idVenda,
      item:id,
      empresa: 0        
    },config)
    .then(function (response) {
      setConfirmacao(false);
      retornaVenda();        
              
    })
    .catch(function (error) {
      console.log(error);
      setConfirmacao(false);
      
    });

}

const vendaSemNfce = async(npedido)=>{
  setLoad(true)
  const sJson = {}
    sJson.loja= localStorage.getItem('loja');
    sJson.empresa=0;
    sJson.orcamento=npedido;
    sJson.caixa='001';
          
     const res = await api.post(vhostV1('webcash/vendas/orcamentoxvenda'), sJson, config)  
    
   .then(function(response){
    console.log(response);
    setConfirmaEmissao(true);
    setSucesso('S');
    setNavegar(true);
   }).catch((erro) => {
    setSucesso('N');
    console.log(erro);
    
   })
 
}
   
useEffect(() => {
  // Atualiza o valorASerPago se totalPedido mudar
  setValorASerPago(totalComDesconto.toString());
}, [totalComDesconto]);

const valorRestante = totalComDesconto - valorTotalPago;

useEffect(() => {
  setValorASerPago(valorRestante);
}, [valorTotalPago, totalComDesconto]);


function fecharVenda(){
  setAutorizaFechamento(false);
  api.post(vhostV1('webcash/vendas/fecharDav'), {
    pedido: idVenda,
    tipo: 1        
  },config)
  .then(function(response){
    
    setMensagem('');
    setSucesso('S');
    setConfirmaPagamento(false);
    if(tipoDoc > 0){
      enviarNFce(numeroPedido,65);
      console.log('Vendi com NFCe');
      
      
    }else{vendaSemNfce(numeroPedido);
      console.log('Vendi sem NFCe');
      
    } // Fechar o alerta após o sucesso
    
    console.log()
    
    

}).catch((erro) =>{
    setMensagem(erro);
    setSucesso('N');
    setConfirmaPagamento(false);
});

}

    
    


    async function EmitirNFCe () { 

      const tItens = await enviarNFce();
    
      console.log(tItens);
        
    }

    useEffect(() => {
      if (sucesso === 'S') {
        navigate('/app/listapedidos');
      }
    }, [sucesso, navigate]);

   
   
    const enviarNFce = async(npedido,tipo)=>{
      setLoad(true)
      const sJson = {}
        sJson.loja=localStorage.getItem('loja'); 
        sJson.empresa=0;
        sJson.dav=npedido;
        sJson.tipo=tipo;
              
         const res = await api.post(vhostV1('webcash/nfe/emitir/nfe'), sJson, config)  
        
        if (res.status===200) {      
         filtrarPedidos(); 
         setConfirmaEmissao(true) 
         setNavegar(true);      
        }else {
          setLoad(false)
          //filtrarPedidos();
          setmsgErro(res.response.data.erro);
          setErroEmissao(true)
          setNavegar(true);
        }      
     
    }

    function filtrarPedidos(){
      setLoad(true)
      //const dt = new Date(dataInical);

      //const iso = dt.toISOString();
      //console.log(iso); // 👉️ "2022-07-21T00:00:00.000Z"


      api.post(vhostV1('webcash/dashboard/listapedidos'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
       //dtinicial:"2023-02-13T13:45:00.000Z",
        dtinicial:dataInical,
        dtfinal:dataFinal,
        tipo:tipo,
        caixa:caixa
      },config)
      .then(function (response) {   
        setPedidos(response.data.dados); 
        setSresult(response.data.result); 
        setLoad(false);
    
      })
      .catch(function (error) {
        console.log(error);
        setLoad(false);
                
      });
      
       

    }  

    function filtraProdutos(){
      const sJson = {}
      sJson.loja=localStorage.getItem('loja'); 
      sJson.empresa=0;
      sJson.grupo=0;
      sJson.subgrupo=0;
      sJson.linha=0;
      sJson.estoque_diferente=false;
      sJson.estoque_negativo=false;
      sJson.preco_zerado=false;
      sJson.inativo=false;
      sJson.pagina= 1;
      sJson.regPag=50;
      sJson.busca= buscaProduto;        
      
        api.post(vhostV1('webcash/produto/listar'),sJson,config)
          .then(function (response) {
            setProdutos(response.data.dados); 
            setSresult(response.data.result)
                                                  
                     
          })
          .catch(function (error) {
            console.log(error);
            setSucessoBusca(true);  
                    
          });
           
    }

    //buscar finalizadora
   useEffect(function(){
    
    api.get(vhostV1('webcash/finalizadora/ListaFinalizadoras'), config)
      .then(function (response) {
          if (response && response.data && response.data.dados){
            const dados = response.data.dados;
            setFinalizadora(dados);
          
            const filtradas = dados.filter(forma => forma.COD_FINALIZADORA <= 50);
          setFinalizadorasFiltradas(filtradas); 
          console.log(response.data.dados)
          setResult(1);
        }else{
          setResult(0);
        }       
      })
      .catch(function (error) {
        console.log(error);
                
      });

      
  },[])

  useEffect(() => {
    // Filtra finalizadoras de acordo com o tipo de documento selecionado
    if (tipoDoc) {
      const filtradas = finalizadora.filter(
        forma => forma.TIPO_DOC === tipoDoc && forma.COD_FINALIZADORA <= 50
      );
      setFinalizadorasFiltradas(filtradas);
    }
  }, [tipoDoc, finalizadora]);


  useEffect(() => {
    setTotalComDesconto(Number(totalPedido-desconto))
   }, [totalPedido, desconto])


   function retornaVenda(){
      api.get(vhostV1('webcash/vendas/retornaVenda/'+idVenda),config)
       .then(function (response) {
        const dados = response.data.dados;
          setTotalPedido(dados.TOTAL);
          setNumeroPedido(dados.COD_ORCAMENTO);
          setResponsavel(dados.RESPONSAVEL);
          setDesconto(dados.DESCONTO);
          console.log(dados);
         if(dados.ITENS){    
            setItens(response.data.dados.ITENS);     
            console.log(response.data.dados.ITENS);   
        }else{
         setItens([]);
         
        }

                  
       })
       .catch(function (error) {
        
         console.log(error);

                 
       });
    }

    const totalDesconto = Itens.reduce((acc, item) => acc + item.DESCONTO, 0);
  

    const valorNota = totalPedido;

    const formatCurrency = (value) => {
      return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    };
    

    


    const [isAdicaoOpen,setIsAdicaoOpen] = useState(false);

    function openModalAdicao(){
       setIsAdicaoOpen(true)
    }

   async function closeModalAdicao(){
     retornaVenda();   
     setIsAdicaoOpen(false)        
    }

    
    

     const handleChange = (e) => {
      const selectedValue = e.target.value;
      const selectedOption = finalizadorasFiltradas.find(forma => forma.DESCRICAO === selectedValue);
  
      if (selectedOption) {
        setDescricaoForma(selectedValue);
        setFormaPag({
          descricao: selectedValue,
          codigo: selectedOption.COD_FINALIZADORA
        });
        setTipoDoc(selectedOption.TIPO_DOC); // Atualiza o filtro com o tipo de documento selecionado
      }
    };


    const gravaRecebimentos = () => {
      console.log('entrei na função');
      
      // for(var i = 0; 3;i++){
      //   console.log('entrei no for');
        
      //   gravaRecDav(
      //     pagamentos[i].valor,               // valorRecebido
      //     pagamentos[i].formaPagamento,      // descricaoPagamento
      //     pagamentos[i].codigoFinalizadora   // finalizadoraCod
      //   );
        
      //   console.log(pagamentos[i]);
      // }
      
      
      
      
      
      pagamentos.forEach((pagamento) => {
        gravaRecDav(
          pagamento.valor,               // valorRecebido
          pagamento.formaPagamento,      // descricaoPagamento
          pagamento.codigoFinalizadora   // finalizadoraCod
        );
        
        
      
     setConfirmaPagamento(true);
      })};
    

   
    
    

    
    

    const registrarPagamento = () => {
      if(formaPag.codigo === 0 || null){
        {setmsgErro("Selecione uma forma de pagamento");
          setErrovalidacao(true)}
      }else{
    
        const valorPagar = parseFloat(valorASerPago.replace('.', ',').replace(',', '.'));

      if (valorPagar > 0 && valorPagar <= valorRestante) {
        // Atualizar o estado de pagamentos
        const novoPagamento = {
          formaPagamento: formaPag.descricao,
          valor: valorPagar,
          codigoFinalizadora: formaPag.codigo
        };
      
        // Atualizar a lista de pagamentos
        setPagamentos(prevPagamentos => {
          // Adiciona o novo pagamento à lista de pagamentos
          return [...prevPagamentos, novoPagamento];
        });
      
        // Atualizar o valor total pago
        setValorTotalPago(prevValor => prevValor + valorPagar);
      
        // Atualizar o valor do pagamento realizado na lista de pagamentos realizados
        setPagamentosRealizados(prevPagamentosRealizados => {
          // Determina o índice para atualização
          const index = prevPagamentosRealizados.length ;
          
          // Atualiza o pagamento realizado atual
          const pagamentoRealizadoAtualizado = {
            ...(prevPagamentosRealizados[index] || {}),
            valor: (prevPagamentosRealizados[index]?.valor || 0) + valorPagar
          };
      
          // Cria uma nova lista de pagamentos realizados
          const novosPagamentosRealizados = [...prevPagamentosRealizados];
          novosPagamentosRealizados[index] = pagamentoRealizadoAtualizado;
          
          return novosPagamentosRealizados;
        });
        
        
        
    
        // Limpar o campo de valor a ser pago
        setValorASerPago(valorRestante.toFixed(2).replace('.', ','));
       
        // Verificar se atingiu o total da venda para fechar e gravar
        if (valorTotalPago >= totalComDesconto) {
          calcularTroco(); // Isso vai gravar no RecDav e resetar valores
        }
      }if(valorASerPago >= (totalComDesconto - valorTotalPago) ){
        calcularTroco();
      } else {
        console.log('Valor inválido ou excede o valor restante.');
      }
     } };

    const [troco, setTroco] = useState(0);
    

    const calcularTroco = () => {
      const valorPagar = parseFloat(valorASerPago.replace('.', ',').replace(',', '.'));
      const calculo = valorPagar - valorRestante;
     
      if (calculo >= 0) {
        setTroco(calculo);
        // setConfirmaPagamento(true);
        // gravaRecDav(); // Gravação no RecDav
        gravaRecebimentos()
        
        
      } else {
        alert('Valor insuficiente para completar o pagamento.');
      }
    };

    const resetarValores = () => {
      setFormaPag('');
      setValorTotalPago(0);
      setPagamentos([]);
      setPagamentosRealizados([]);
      setValorASerPago(totalComDesconto);
    };
  

    
    

  
   function gravaRecDav( valorRecebido,descricaoPagamento, finalizadoraCod){
                     
    api.post(vhostV1('webcash/vendas/gravaRecDav'),{
      seq:1,
      id_orcamento:idVenda,
      data: '',       
      hora :'',      
      valor: valorRecebido,     
      nsu:'000000',        
      autorizacao:'000000',
      rede:'',
      produto: '',
      logico:'000000',
      tipo: descricaoPagamento,       
      nrParcelas:1, 
      cnpj_Adquirente:'00000000000',
      id_Remoto:1,
      cod_finalizadora: finalizadoraCod,
      
     },config)
     .then(function (response) {
       setRecebito(1); 
       
       
       
       
      ;                  
                   
                
     })
     .catch(function (error) {
      setRecebito(0);                                   
       console.log(error);

               
     });


  }


  function adicionaCliente(e, ){
   
    api.post(vhostV1('webcash/vendas/adicionacliente'), {
      pedido:idVenda,
      cliente:e,
      tipo:"P"
     },config)
     .then(function (response) {
       
       console.log(response);                               
                
     })
     .catch(function (error) {
      setRecebito(0);                                   
       console.log(error);

               
     });


 }

  

   

   
   function listaPessoa(e){
      e.preventDefault();
      api.post(vhostV1('webcash/cadastro'), {
          loja: localStorage.getItem('loja'),
          empresa: 0,
          tipo:"%",
          busca: buscaPessoa
        },config)
        .then(function (response) {
          setPessoas(response.data.dados);  
          setResultPessoa(response.data.result);
          console.log(response.data.dados);                                  
                   
        })
        .catch(function (error) {
         
          console.log(error);
  
                  
        });
      }
  
  
      function pesquisaPessoa(e){
        
        adicionaCliente(e.substring(1,6));         
      api.post(vhostV1('webcash/cadastro/'+e.substring(1,6)), {
          loja: localStorage.getItem('loja'),
          empresa: 0,
        },config)
        .then(function (response) {
          console.log(response.data.dados.COD_CADASTRO)
          setRazao(response.data.dados.RAZAO); 
          setCnpj(response.data.dados.CNPJ);
          setFantasia(response.data.dados.FANTASIA); 
          setbairro(response.data.dados.BAIRRO);
          setcidade(response.data.dados.CIDADE);
          setuf(response.data.dados.ESTADO);
          setfone(response.data.dados.TELEFONE); 
          setcep(response.data.dados.CEP);
          setMunicipal(response.data.dados.MUNICIPAL);
          setEstadual(response.data.dados.ESTADUAL);
          setendereco(response.data.dados.ENDERECO);


                   
        })
        .catch(function (error) {
         
          console.log(error);
  
                  
        });
                
      }
      
    //   function mostrarCampo(event) {
    //     event.preventDefault();
    //     const campoOculto = document.getElementById("campoOculto2");
    //     campoOculto.style.display = "block";
        
    // } 


      // criando um pedido
      useEffect(function(){
        
         api.post(vhostV1('webcash/vendas/iniciarVenda'), {
            empresa:0,
            idMesa: 0,
            codColaborador: localStorage.getItem("colaborador"),
            qtdPessoas: 1,
            nome: "",
            caixa: "001",
            codIndicado: ""
           },config)
           .then(function (response) {
              setIdVenda(response.data.dados.cod_orcamento); 
              
              
             }                               
           )
           .catch(function (error) {
             console.log(error);
                     
           });    
           
       },[])


   return <div className="conteiner-fluid titulo ">
   <Navbar/>

   <ModalAdicaoItem isOpen={isAdicaoOpen}
                 onRequestClose={closeModalAdicao}
                 idVenda={idVenda}
                 setIsAdicaoOpen={setIsAdicaoOpen}
                 titulo="Adicionar Item "
                 
                 />   


   
   <div className="offset-2 col-8">
         <h5>Novo pedido</h5> 

         <hr />
          <div className="conteiner-fluid">
          <form className="row tela">
             
             <div className="col-2">
                 <label htmlFor="inputAddress" className="form-label">N PEDIDO</label>
                 <input type="text" value={idVenda} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
             </div>

             <div className="col-4">
                 <label htmlFor="inputAddress" className="form-label">VENDEDOR</label>
                 <input type="text" value={localStorage.getItem("usuario")} className="form-control" id="inputCodigo" tabIndex="1"   disabled/>
             </div>

             <div className="col-3">
                 <label htmlFor="inputAddress" className="form-label">DATA</label>
                 <input type="text"  value={dataAtual} className="form-control" disabled/>
             </div>

             <div className="col-3">
                 <label htmlFor="inputAddress" className="form-label">TOTAL</label>
                  <NumericFormat className="form-control"
                                      id="inputValorTotal" 
                                      value={totalPedido.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      
                                 />  
             </div>
              
             <div className="col-md-12">
                       <label htmlFor="inputCity" className="form-label">CLIENTE</label>
                        <div className="input-group mb-3">
                           <input type="text"  value={razao} className="form-control" id="inputNcm" tabIndex="7" disabled/>
                            <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            pesquisar
                            </button>
                        </div>

                        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                         <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="staticBackdropLabel">Pesquisa Cadastro</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div >
                                <div className="input-group mb-3">
                                  <input onChange={(e) => setBuscaPessoa(e.target.value)} type="text" className="form-control" placeholder="Digite sua pesquisa" aria-describedby="button-addon2" />
                                  <button onClick={listaPessoa} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
                                </div>
                               
                                { resultPessoa === 1?
                                <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Razão</th>
                                    <th scope="col">Ação</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                { pessoas.map((pessoa)=> {
                                    return <tr key={pessoa.COD_CADASTRO}>
                                        <td>{pessoa.CNPJ}</td>          
                                        <td>{pessoa.RAZAO}</td>
                                        <td>
                                          <a href=""   onClick={()=> pesquisaPessoa(("000000" + pessoa.COD_CADASTRO).slice(-6) +' - ' +pessoa.RAZAO)} value={pessoa.RAZAO} >
                                            <i className="fas fa-edit icone-acao red" data-bs-dismiss="modal"></i>
                                          </a>                                        
                                        </td>
                                    </tr>
                                } )}  
                                </tbody>
                            </table>:<BuscaVazaia/>}   

                                </div>
                            </div>
                            <div className="modal-footer">
                                
                            </div>
                            </div>
                        </div>
                        </div>
                        
                      </div>
                      <div className="accordion accordion-flush" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            DADOS DO DESTINATÁRIO
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                          <div className="accordion-body"></div>

                      <div className="row">
                       
                        <div className="col-8">
                            <label htmlFor="inputAddress" className="form-label">FANTASIA</label>
                            <input type="text"  value={fantasia} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
                        </div>
                        <div className="col-4">
                            <label htmlFor="inputAddress" className="form-label">CNPJ/CPF</label>
                            <input type="text" value={cnpj} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
                        </div>
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">INSC EST</label>
                            <input type="text" value={inscEstadual} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
                        </div>
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">INSC MUN</label>
                            <input type="text" value={inscMunicipal} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
                        </div>
                        <div className="col-8">
                            <label htmlFor="inputAddress" className="form-label">ENDEREÇO</label>
                            <input type="text" value={endereco} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
                        </div>

                        <div className="col-3">
                            <label htmlFor="inputAddress" className="form-label">BAIRRO</label>
                            <input type="text" value={bairro} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
                        </div>

                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">CEP</label>
                            <input type="text" value={cep} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
                        </div>

                        <div className="col-4">
                            <label htmlFor="inputAddress" className="form-label">CIDADE</label>
                            <input type="text" value={cidade} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
                        </div>

                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">FONE</label>
                            <input type="text" value={fone} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
                        </div>

                        <div className="col-1">
                            <label htmlFor="inputAddress" className="form-label">UF</label>
                            <input type="text" value={uf} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
                        </div>

                      </div>
                      
                      </div>
                    </div>
                  </div>

                  
             <br />
             <hr />
             <h4>Itens do Pedido</h4>
             <hr />
             <div className="col-4">
               <button className="btn btn-success btn-cli" onClick={openModalAdicao} type="button" ><i className="bi bi-upc"></i>Adicionar</button>
             </div>
                       
           </form>

           <table className="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">Codigo</th>
                <th scope="col">Descricao</th>
                <th scope="col">Qtd</th>
                <th scope="col">Venda</th>
                <th scope="col">Desconto</th>
                <th scope="col">Total</th>
                <th scope="col">Ação</th>

                
                </tr>
            </thead>
            <tbody className="table-group-divider">
              {Itens.length > 0?  
                 Itens.map((item,i)=> {
                  return <tr key={item.ITEM}>
                      <td>{item.COD_PRODUTO}</td>          
                      <td>{item.DESCRICAO}</td>
                      <td>{Number(item.QTD).toFixed(3)}</td>
                      <td>{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.VENDA)}</td>
                      <td>{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.DESCONTO)}</td>
                      <td>{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.TOTAL)}</td>
                      <td><button onClick={() => confirmadeleteUser(item.ITEM)} className="dropdown-item"><i className="bi bi-trash-fill"></i></button></td> 
                  </tr>
              } ):null}  
            </tbody>
          </table>
           
         </div>    
         
         <Link to="/app/listapedidos" type="submit" className="btn btn-outline-primary butao"  tabIndex="14">Desistir</Link>
         {/*<button type="submit" onClick={fecharVenda} className="btn btn-primary butao" tabIndex="13">Salvar</button>*/}
        
          <button type="button" className="btn btn-primary butao" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Finalizar
          </button>

          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">Recebimento</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <div className="d-flex justify-content-between align-items-end p-3">
      <Card title="Sub-total" style={{borderLeft: 'solid 5px #FFA500'}} value={formatCurrency(valorNota)} />
      <Card title="Desconto" style={{borderLeft: 'solid 5px #dc3545'}}  value={formatCurrency(totalDesconto)} />
      <Card title="Valor" style={{borderLeft: 'solid 5px #28a745'}} value={formatCurrency(valorRestante)} />
         </div>
            
            {/* <div className="row col-3"  id='campoOculto2' >
          <label htmlFor="inputAddress" className="form-label"  >DESCONTO</label>   
                <NumericFormat className="form-control"
                              id="inputVenda" 
                              onBlur={calculaDesconto} 
                              value={desconto.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}                      
                              allowLeadingZeros
                              decimalSeparator=','
                              thousandSeparator='.'
                              decimalScale={2}
                              onChange={(e) => setDesconto(e.target.value)}
                              onValueChange={(values,suorceInfo)=>{
                                setDesconto(values.value);
                              }}
                                                       
               />   
          </div> */}
            <br />
            <div className="row mb-3">
              <select
                onChange={handleChange}
                value={formaPag.descricao}
                className="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
              >
               <option value="">Selecione a forma de pagamento</option> 
                {finalizadorasFiltradas.map((forma) => (
                  <option key={forma.COD_FINALIZADORA} value={forma.DESCRICAO}>
                    {forma.COD_FINALIZADORA} - {forma.DESCRICAO}
                  </option>
                ))}
              </select>
            </div>

            {formaPag.codigo === 2 ? (
              <div className="row">
                <div className="col-4">
                  <label htmlFor="inputCnpj" className="form-label">CNPJ ADQUIRENTE</label>
                  <input type="text" className="form-control" id="inputCnpj" tabIndex="1" required autoComplete="off" />
                </div>
                <div className="col-6">
                  <label htmlFor="inputAutorizacao" className="form-label">NÚMERO AUTORIZAÇÃO</label>
                  <input type="text" className="form-control" id="inputAutorizacao" tabIndex="1" />
                </div>
              </div>
            ) : null}
             {formaPag.codigo === 3 ? (
              <div className="row">
                <div className='col-4'>
                  <select className="form-select form-select-lg mb-3" id="inputCartao">SELECIONE CRÉDITO OU DÉBITO:
                    <option value={1}>Crédito</option>
                    <option value={2}>Débito</option>
                  </select>
                </div>
                <div className='col-4'>
                  <select className='form-select form-select-lg mb-3' id="inputBandeira">SELECIONE A BANDEIRA:
                    <option value={1}>VISA</option>
                    <option value={2}>MASTERCARD</option>

                  </select>
                </div>
                <div className="col-4">
                  <label htmlFor="inputCnpj" className="form-label">CNPJ DA CREDENCIADORA</label>
                  <input type="text" className="form-control" id="inputCnpj" tabIndex="1" required autoComplete="off" />
                </div>
                <div className="col-6">
                  <label htmlFor="inputAutorizacao" className="form-label">NÚMERO AUTORIZAÇÃO</label>
                  <input type="text" className="form-control" id="inputAutorizacao" tabIndex="1" />
                </div>
              </div>
            ) : null}



            <div className="row align-items-center">
              <div className="col-md-4">
                <label htmlFor="inputValorPago" className="form-label">Valor a ser pago</label>
                <NumericFormat
                  type='text'
                  className="form-control"
                  id="inputValorPago"                  
                  defaultValue={totalComDesconto}
                  value={valorASerPago}
                  onValueChange={(values) => setValorASerPago(values.value)}
                  autoComplete="off"
                  thousandSeparator='.'
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  
                  
                />
              </div>
              <div id='botao-pagar' className="col-md-4">
                <div className="d-flex">
                {valorRestante > 0 && (
                    <button type="button" className="btn btn-primary" onClick={registrarPagamento}>Registrar Pagamento</button>
                  )}
                  {valorRestante <= 0 && (
                    <button type="submit" className="btn btn-success" onClick={calcularTroco}>Receber</button>
                  )}
            </div>
            </div>
            </div>

            <br />
            <button onClick={gravaRecebimentos}>Teste</button>
            <h3>Valor Total Pago: {valorTotalPago.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</h3>
            <h3>Valor Restante: {valorRestante.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</h3>
            <br />
            <h3>Pagamentos Realizados:</h3>
            <ul>
              {pagamentos.map((pagamento, index) => (
                <li key={index}>{pagamento.formaPagamento}: {pagamento.valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</li>
              ))}
            </ul>
          </div>
          <div className="modal-footer">
            {/* <button type='button' className='btn btn-danger' data-bs-dismiss='modal' onClick={mostrarCampo}>$</button> */}
            <button type="button" onClick={resetarValores} className="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
           
          </div>
        </div>
      </div>
    </div>
  

        
         
         {
           sucessoBusca ? 
           <SweetAlert
              danger                         
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="algo deu errado"
              onConfirm={() => setSucessoBusca(false)}                            
            >
              Produto Não encontrado 
            </SweetAlert>:null
           
          }

{
           erroEmissao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErroEmissao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }

{
           confirmacao ? 
           <SweetAlert
              warning
              showCancel
              showCloseButton
              confirmBtnText="Sim"
              confirmBtnBsStyle="danger"
              cancelBtnText="Não"
              cancelBtnBsStyle='ligth'
              title="Atenção"
              onConfirm={() =>deleteUser(confirmacaoId)}
              onCancel={() => setConfirmacao(false)}
              focusCancelBtn
              reverseButtons
            >
              Deseja excluir este Item!
            </SweetAlert>:null
           
          }

{
           erroValidacao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErrovalidacao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }

{
           confirmaEmissao ? 
           <SweetAlert
              success                         
              confirmBtnText="OK"
              confirmBtnBsStyle="success"
              title="Parabéns"
              onConfirm={() => setConfirmaEmissao(false)}                            
            >
              Nota Emitida com sucesso!
            </SweetAlert>:null
           
          }

{
           confirmaPagamento ?
           <SweetAlert
              success                     
              confirmBtnText="OK"
              confirmBtnBsStyle="success"
              title="Parabéns"
              onConfirm={() =>fecharVenda()}                            
            >
              Troco a devolver: {troco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </SweetAlert>:null
           
          }

             {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
             {navegar ? <Navigate to='/app/listapedidos' /> && window.location.reload()  : null}

        
      </div> 

      
   </div>
   


}


export default NovoPedido;