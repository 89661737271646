import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../services/api';
import './pedidos.css';
import Navbar from '../navbar/navbar';
import logoNfce from '../../images/nfce.png'
import logoRecibo from '../../images/recibo.png'
import { vhostV1 } from '../funcoes';
import BuscaVazaia from '../components/buscavazia/buscaVazia';
import PedidosPdf from '../reports/pedidos/sinteticos';
import VendasPorFinalizadora from '../home/VendasPorFinalizadora';
import VendasPorCupom from '../reports/vendas/vendasporcupom';
import Loading from '../components/loading/Loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import CupomFiscal from '../reports/nfe/CupomFiscal';
import cupom from '../reports/nfe/venda';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Widget from '../components/widget/widget';
import customizeScrollbar from "customsb";
import VendaDashboardPdf from '../reports/pedidos/vendaDashboardPdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PedidosDashboard() {

    const [pedidos,setPedidos] = useState([]); 
    const [result, setResult] = useState(0);
    const [sResult,setSresult] = useState(0);
    const [tpRelatorio,setTipoRelatorio] = useState(0);
    const [tipo,setTipo] = useState('TODOS');
    const [caixa,setCaixa] = useState('TODOS');  
    const [dataInical,setDataInicial] = useState(new Date); 
    const [dataFinal,setDataFinal] = useState(new Date);
    const [load,setLoad] = useState(false);
    const [confirmaEmissao,setConfirmaEmissao] = useState(false);
    const [erroEmissao,setErroEmissao] = useState(false);
    const [base64Data, setBase64Data] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [msgErro,setmsgErro] = useState('');
    const [rankingProduto, setRankingProduto] = useState([]);
    const [totalMes,setTotalMes] = useState(0);   
    const [totalDia,setTotalDia] = useState(0);   
    const [qtdVenda,setQtdVenda] = useState(0); 
    const [tiket,setTiket] = useState(0);
    const [rankVendedor, setRankVendedor] = useState([]);
    const [finalizadoraVenda, setFinalizadoraVendas] = useState([]);
    const [vendaPix, setVendaPix] = useState(0);
    const [vendaDinheiro, setVendaDinheiro] = useState(0);
    const [vendaCartao, setVendaCartao] = useState(0);



    let config = {
        headers: {
           'Authorization': localStorage.getItem("token")
           }
        }

        async function fetchBase64Data(schave){
            try {
              setLoad(true)
                const response = await api.post(vhostV1('webcash/nfe/danfepdf'),{
                loja: localStorage.getItem('loja'),
                empresa: 0,
                chave:schave
              },config);
              setLoad(false)
              console.log(response)
              setBase64Data(response.data.dados.data.base64);
              convertAndOpenPDF(response.data.dados.data.base64);
            } catch (error) {
              console.error('Erro ao buscar base64 da API:', error);
            }
          };
    
          const convertAndOpenPDF = (base64String) => {
            const byteCharacters = atob(base64String);
            const byteArrays = [];
        
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
              const slice = byteCharacters.slice(offset, offset + 512);
        
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
        
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
        
            const pdfBlob = new Blob(byteArrays, { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
        
            window.open(pdfUrl, '_blank');
          };    


 // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
    // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
    customizeScrollbar({
      scrollbarWidth: "10px",
      trackBackground: "#333",
      thumbBackground:
        "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
      thumbBorderRadius: "10px",
    });
  }, []);

  useEffect(function(){
    api.post(vhostV1('webcash/dashboard/produtoMaisVendido'),{
        loja: localStorage.getItem('loja'),
        empresa: 0,
        data: dataInical
    }, config)
    .then(function (response){
        console.log(response);
        setRankingProduto(response.data.dados);
        
    })
    .catch(function (error){
        console.log(error);
    })
},[])

if(rankingProduto !== undefined){
rankingProduto.sort((a, b) => b.qtd - a.qtd);}


useEffect(function(){
  api.post(vhostV1('webcash/dashboard/rankVendedor'),{
      loja: localStorage.getItem('loja'),
      empresa: 0,
      data: dataInical
  }, config)
  .then(function (response){
      console.log(response);
      setRankVendedor(response.data.dados);
      if(response && response.data && response.data.dados){
          setResult(1);
      }
      
      
  })
  .catch(function (error){
      console.log(error);
  })
},[])


useEffect(function(){
  api.post(vhostV1('webcash/dashboard/vendafinalizadoras'), {
      loja: localStorage.getItem('loja'),
      empresa: 0,
      data: dataInical
       
  },config)
  .then(function(response){
      console.log(response.data.dados);
      const dados = response.data.dados;
      setFinalizadoraVendas(dados);
      const somarValoresPorCodigo = (dados, codigo) => {
          return dados
              .filter(dado => dado.cod_finalizadora === codigo)  // Filtra os objetos com o código desejado
              .reduce((acumulador, dado) => acumulador + dado.valor_final, 0);  // Soma os valores finais
      };

      setVendaDinheiro(somarValoresPorCodigo(dados, 1));
      setVendaCartao(somarValoresPorCodigo(dados, 2));  
      setVendaPix(somarValoresPorCodigo(dados, 3));          
      
  })
  .catch(function (error){
      console.log(error);
  });
},[])  


useEffect(function(){
  api.post(vhostV1('webcash/dashboard/widget'), {
    loja: localStorage.getItem('loja'),
    empresa: 0
    },config)
  .then(function (response) {        
    console.log(response);
    setTotalMes(response.data.dados.total);           
    setTotalDia(response.data.dados.venda);           
    setQtdVenda(response.data.dados.qtd);           
    setTiket(response.data.dados.ticket);           

  })
  .catch(function (error) {
    console.log(error);
            
  });
          
},[])




  const getDadosRelatorio = async()=>{
        
    let response = {};
    try {
      response = await api.post(vhostV1('webcash/vendas/relsintetico'),{
        loja: localStorage.getItem('loja'),
        empresa: 0,
       //dtinicial:"2023-02-13T13:45:00.000Z",
        dtinicial:dataInical,
        dtfinal:dataFinal,
        tipo:tipo,
        caixa:caixa
      }, config)
      
    } catch (error) {
      console.log(error);          
    }
     
    return response.data.dados;
  
    //setItens(response.data.dados); 
    //setResultItens(response.data.result)     
  
  }


  const getDadosRelatorioFinalizadora = async()=>{
    
    let response = {};
    try {
      response = await api.post(vhostV1('webcash/vendas/listavendas/finalizadora'),{
        loja: localStorage.getItem('loja'),
        empresa: 0,
       //dtinicial:"2023-02-13T13:45:00.000Z",
        dtinicial:dataInical,
        dtfinal:dataFinal,
        tipo:tipo,
        caixa:caixa
      }, config)
      
    } catch (error) {
      console.log(error);          
    }
     
    return response.data.dados;
  
    //setItens(response.data.dados); 
    //setResultItens(response.data.result)             
  
  }

  const getDadosRelatorioCupom = async()=>{
    
    let response = {};
    try {
      response = await api.post(vhostV1('webcash/vendas/relitens'),{
        loja: localStorage.getItem('loja'),
        empresa: 0,
       //dtinicial:"2023-02-13T13:45:00.000Z",
        dtinicial:dataInical,
        dtfinal:dataFinal,
        tipo:tipo,
        caixa:caixa
      }, config)
      
    } catch (error) {
      console.log(error);          
    }
     
    return response.data.dados;
  
    //setItens(response.data.dados); 
    //setResultItens(response.data.result)
         
  
  }




  async function gerarRelatorio () { 

    if(tpRelatorio === 0 ){
    const tItens = await getDadosRelatorio();
      PedidosPdf(tItens);
    }else 
    if(tpRelatorio === 1){
      const tItens = await getDadosRelatorioFinalizadora();
      VendasPorFinalizadora(tItens);
    }else{
      const tItens = await getDadosRelatorioCupom();
      VendasPorCupom(tItens);
    }      
           
  }  


function filtrarPedidos(){
  setLoad(true)
  //const dt = new Date(dataInical);

  //const iso = dt.toISOString();
  //console.log(iso); // 👉️ "2022-07-21T00:00:00.000Z"


  api.post(vhostV1('webcash/dashboard/listapedidos'), {
    loja: localStorage.getItem('loja'),
    empresa: 0,
   //dtinicial:"2023-02-13T13:45:00.000Z",
    dtinicial:dataInical,
    dtfinal:dataFinal,
    tipo:tipo,
    caixa:caixa
  },config)
  .then(function (response) {   
    
    setPedidos(response.data.dados); 
    setSresult(response.data.result); 
    setLoad(false);

  })
  .catch(function (error) {
    console.log(error);
    setLoad(false);
            
  });
  
   

}  



function imprimirNota(){

  CupomFiscal(cupom);

}


async function EmitirNFCe (e,tipo) { 
  setLoad(true)
  
  const tItens = await enviarNFce(e,tipo);    
  //console.log('retorno '+tItens);
  
  filtrarPedidos();
  
    
}

const enviarNFce = async(npedido,tipo)=>{
  setLoad(true)
  const sJson = {}
    sJson.loja=localStorage.getItem('loja'); 
    sJson.empresa=0;
    sJson.dav=npedido;
    sJson.tipo=tipo;
          
     const res = await api.post(vhostV1('webcash/nfe/emitir/nfe'), sJson, config)  
    
    if (res.status===200) {      
     filtrarPedidos(); 
     setConfirmaEmissao(true)       
    }else {
      setLoad(false)
      //filtrarPedidos();
      setmsgErro(res.response.data.erro);
      setErroEmissao(true)
    }      
 
}

const handleClick = () => {
  VendaDashboardPdf({
      finalizadoraVenda,
      tiket,
      rankVendedor,
      rankingProduto,
      totalDia,
      qtdVenda
  });
};



//buscar pedidos
useEffect(function(){
  api.post(vhostV1('webcash/dashboard/listapedidos'), {
    loja: localStorage.getItem('loja'),
    empresa: 0,
  // dtinicial:"2023-02-13T13:45:00.000Z",
    dtinicial:dataInical, 
    dtfinal:dataFinal,
    tipo:tipo,
    caixa:caixa
  },config)
  .then(function (response) {   
    console.log(response.data);     
    setPedidos(response.data.dados); 
    setSresult(response.data.result); 

  })
  .catch(function (error) {
    console.log(error);
            
  });
  
  
},[])





  return <div>
     <Navbar/>
        <div className="container-fluid titulo offset-lg-1 col-lg-10">
        
       
        <div className="d-flex justify-content-between">
              <h2 className="d-inline">Vendas - Dashboard</h2>
              <div>
                
                <button type="button" className="btn btn-secondary btn-cli" data-bs-toggle="modal" data-bs-target="#moda-filtro"><i className="bi bi-funnel-fill"></i>Filtros</button>
                <button type="button" onClick={handleClick} className="btn btn-danger btn-cli" data-bs-dismiss="modal"><i className='bi bi-printer-fill'></i>Imprimir</button>
                {/*<button type="button" className="btn btn-danger btn-cli"  data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-printer-fill"></i> Relatório</button>
                <SalesReport/>*/}
              </div>
        </div> 
        
        <hr />

        <div className="row">
          <Widget/>
        </div>

        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Relátorio de Vendas</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="contnt-modal">
                   <h5>Tipo do relatório </h5> 
                  <div className="form-check">
                    <input className="form-check-input" value={0}  type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Sintético
                    </label>
                  </div>
                 

                  <div className="form-check">
                    <input className="form-check-input" value={2}  type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                      Finalizadora
                    </label>
                  </div>

                    
                    <hr />

                   <div className="row">
                     <h5>Período  </h5>   
                       <div className="datas col-8 offset-2">
                         <input type="date" onChange={(e)=> setDataInicial(e.target.value+"T10:10:00.000Z")} className='form-control'/>
                         <br />
                         <input type="date" onChange={(e)=> setDataFinal(e.target.value+"T10:10:00.000Z")} className='form-control'/>
                         </div>
                          
                     </div>

                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                              
                  { sResult === 1?
                  <div>                  
                  <button type="button" onClick={ async()=> await gerarRelatorio()} className="btn btn-primary btn-cli" data-bs-dismiss="modal">Imprimir</button>
                  </div>
                  :null} 
                  

                </div>
              </div>
            </div>
          </div>


          <div className="modal fade" id="moda-filtro" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Filtros</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="contnt-modal">
                    <div className="row">
                      
                    <div >
                        <select className='form-control' name="status" id="status" onChange={(e)=> setTipoRelatorio(e.target.value)}>
                            <option value={0}>Lista</option>
                            <option value={1}>Agrupado por Finalizadora</option>
                            <option value={2}>Agrupado por Cupom</option>
                        </select>
                    </div>                       
                    </div>      
                    <hr />                      

                   <div className="row">
                    <div>
                     <h5>Tipo  </h5>   
                     <div>
                     <div className="form-control d-inline me-3">
                        <select name="status" id="status" onChange={(e)=> setTipo(e.target.value)}>
                            <option value="TODOS">Todos</option>
                            <option value="RECIBO">Recibo</option>
                            <option value="NFCE">NFCe</option>
                        </select>
                    </div>
                    
                   </div>
                   </div>

                   <div>

                   <h5>Caixa  </h5>   
                     <div>
                     <div className="form-control d-inline me-3">
                        <select name="status" onChange={(e)=> setCaixa(e.target.value)} id="status" >
                            <option value="%">Todos</option>
                            <option value="001">001</option>
                            <option value="002">002</option>
                            <option value="003">003</option>
                            <option value="004">004</option>
                        </select>
                     </div>
                    
                     </div>

                     </div>
                          
                     </div>
                     <br />

                     <div className="row">
                    
                    </div>


                    <div className="row">
                     <h5>Período</h5>   
                     <div>
                     <div className='d-flex col-12'>           
                        <input type="date" onChange={(e)=> setDataInicial(e.target.value+"T10:10:00.000Z")} className="form-control" />
                         
                        <input type="date" onChange={(e)=> setDataFinal(e.target.value+"T10:10:00.000Z")} className="form-control" />
                    </div>
                     </div>
                     </div>

                  </div>
                </div>
                <div className="modal-footer">
                  <div>                  
                  { tpRelatorio===0?
                  <button type="button" onClick={filtrarPedidos} className="btn btn-secondary btn-cli" data-bs-dismiss="modal">Ativar</button>
                  :null}
                  
                  
                  </div>

                  

                </div>
              </div>
            </div>
          </div>
       </div>   

  </div>









}

export default PedidosDashboard;