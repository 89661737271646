import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Navbar from '../../navbar/navbar';
import './colaborador.css'
import { api } from '../../../services/api';
import { vhostV1 } from '../../funcoes';
import customizeScrollbar from "customsb";
import SweetAlert from 'react-bootstrap-sweetalert';
import BuscaVazaia from '../../components/buscavazia/buscaVazia';


function Colaborador() {


     const [texto,setTexto] = useState('');
     const [busca,setBusca] = useState("");
     const [colaboradores,setcolaboradores] = useState([]);
     const [navegar, setNavegar] = useState(false);
     const [confirmacao, setConfirmacao] = useState(false);
     const [contas, setContas] = useState([]);
     const [sResult, setSresult] = useState(0);
     const [msgErro, setmsgErro] = useState('');
     const [erroValidacao, setErrovalidacao] = useState(false);
     const [confirmacaoId,setConfirmacaoID] = useState('');

  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  function deleteUser(id){
    api.post(vhostV1('webcash/cadastro/excluir'), {
      loja: localStorage.getItem('loja'),
      empresa: 0,
      cod_cadastro: id
    },config)
    .then(function (response) {
      setConfirmacao(false);
      setNavegar(true);
              
    })
    .catch(function (error) {
      console.log(error);
      setConfirmacao(false);
      
    });
  
 
}

function confirmadeleteUser(id) {
  const podeExcluir = podeExcluirCadastro('9999'); // Chama a função e armazena o resultado

  if (!podeExcluir) { // Verifica se a exclusão não é permitida
    setmsgErro(`Não é possível excluir o cadastro com código ${id} porque existem contas associadas a ele.`);
    setErrovalidacao(true);
  } else {
    setConfirmacaoID(id);
    setConfirmacao(true);
    setBusca('');
  }
}

function podeExcluirCadastro(id) {
  // Verificar se há produtos associados ao grupo
  const contasAssociadas = contas.some(conta => Number(conta.RESPONSAVEL) === Number(id));
  console.log(contasAssociadas);
  console.log(id);
  
  
  
  if (contasAssociadas) {
    console.log(`Não é possível excluir o cadastro com código ${id} porque existem contas associadas a ele.`);
    return false;
  }

  return true;
}

useEffect(function(){
  api.post(vhostV1('webcash/contas'), {
      loja: localStorage.getItem('loja'),
      empresa: 0,
      busca: busca,
      tipo: '%',
      colaborador:0
    },config)
    .then(function (response) { 
      
       setSresult(response.data.result); 
       setContas(response.data.dados);
       console.log(response.data.dados);
               
      }                               
    )
    .catch(function (error) {
      console.log(error);
              
    });

        
},[])

  //buscar clientes
  useEffect(function(){
    
      api.post(vhostV1('webcash/usuario/listar'), {
          loja: localStorage.getItem('loja'),
          empresa: 0,
          busca: busca
        },config)
        .then(function (response) {
          console.log(response);
          
          setSresult(response.data.result);
            setcolaboradores(response.data.dados); 
                   
        })
        .catch(function (error) {
          console.log(error);
                  
        });
  
        
    },[busca])

     // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);


    return <div>
        <Navbar/>
        <div className="container-fluid titulo offset-lg-1 col-lg-10">
        <h1>Cadastro de Colaborador</h1>

        <div className="row">
          <div className="col-4">
            <Link to='/app/novoColaborador' className="btn btn-primary" type="button"><i className="fas fa-plus"></i> Colaborador</Link>
          </div>

          <div className="col-8">
            <div className="input-group mb-3">
              <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" />
              <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="bi bi-search"></i> Pesquisar</button>
            </div>
          </div>
        </div>   
         {sResult === 1?
         <>
         <table id='table-colaborador' className="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">Codigo</th>
                <th scope="col">Nome</th>
                <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
            { colaboradores.map((colaborador)=> {
                return <tr key={colaborador.ID}>
                    <td>{colaborador.ID}</td>          
                    <td>{colaborador.NOME}</td>
                    <td>
                    <Link to={'/app/editaColaborador/' + colaborador.ID}><i className="bi bi-pen-fill"></i></Link>
                    <Link to ="#"><i className="bi bi-trash-fill red"></i></Link>
                    </td>
                </tr>
            } )}
         
  
</tbody>
</table>
</>
:<BuscaVazaia/>} 
{
           confirmacao ? 
           <SweetAlert
              warning
              showCancel
              showCloseButton
              confirmBtnText="Sim"
              confirmBtnBsStyle="danger"
              cancelBtnText="Não"
              cancelBtnBsStyle='ligth'
              title="Atenção"
              onConfirm={() =>deleteUser(confirmacaoId)}
              onCancel={() => setConfirmacao(false)}
              focusCancelBtn
              reverseButtons
            >
              Deseja excluir o cliente!
            </SweetAlert>:null
           
          }

    {navegar ? <Navigate to='/app/colaborador' /> && window.location.reload()  : null}
        

      </div>      
    </div>
}


export default Colaborador;